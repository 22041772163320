<template>
  <div class="page">
    <div class="mbd">
      <GreenTips word='下载APP，使用体验更佳' />
      <p>1、请先在网页绑定手机号</p>
      <p>2、在应用商店搜索：花样起名</p>
      <p>3、下载APP后使用手机号登录</p>
      <button v-if="!info['phone']['has']" class="in_body black" data-value="zc" @click="pull_up">绑定手机号</button>
      <p v-else class=""> 您已绑定，手机号为：{{info['phone']['content'] }} </p>
      <p class='tips'>如绑定中遇到问题，请联系首页客服</p>
    </div>
    <ul id="last_right_b" class="last_right">
      <li  @click="xml_go_to_only" data-value="index"  class="home"><img src="@/assets/img/home.png" /></li>
    </ul>
    <van-popup v-model:show="show.zc" round position="bottom" :style="{ height: '72%' }" @close="on_close">
      <Login></Login>
    </van-popup>
  </div>
</template>

<script>

import store from '@/store'
import Utils from '@/utils/utils'
import Login from '@/components/other/Login'
import GreenTips from '@/components/items/GreenTips'
import axios from 'axios'

export default {
  name: 'bind_tips',
  components: {
    Login, GreenTips
  },
  data () {
    return {
      loading: false,
      niming: store.state.niming,
      info: { phone: { has: false, content: '' } },
      show: { zc: false }
    }
  },
  mounted () {
    this.get_bind()
  },
  methods: {
    get_bind (e) {
      axios.post('/get_bind_info/', {})
        .then(res => {
          if (res.data.code !== 200) {
            Utils.alert({ content: res.data.msg })
            return
          }
          this.info = res.data.data.info
          this.loading = false
        })
    },
    xml_go_to_only (e) {
      Utils.go_to(e)
    },
    pull_up (e) {
      this.show[e.currentTarget.dataset.value] = true
    },
    on_close () {
      this.show = { zc: false }
    }
  }
}
</script>

<style scoped>
.mbd p{
  margin: 0.4rem 0.2rem;
}
</style>
